import { COption } from '../model/COption'

export function getCurrentNode(
  options: COption[],
  paths: string[]
): COption | null {
  let optionsCopy = options
  for (let i = 0; i < paths.length - 1; i++) {
    const path = paths[i]
    let currentOptionsIndex = optionsCopy.findIndex(
      (item) => item.uuid === path
    )
    if (currentOptionsIndex === -1) return null
    optionsCopy = optionsCopy[currentOptionsIndex].childOptions
  }
  let lastPath = paths[paths.length - 1]
  let result = optionsCopy.find((item) => item.uuid === lastPath) || null
  return result
}

export function getRest(options: COption[], paths: string[]): COption[] {
  let rest = options
  for (let i = 0; i < paths.length; i++) {
    const path = paths[i]
    let currentOptionsIndex = rest.findIndex((item) => item.uuid === path)
    if (currentOptionsIndex === -1) return []
    rest = rest[currentOptionsIndex].childOptions
  }
  return rest
}

export function getRestPathValue(options: COption[]): string[][] {
  let restPath: string[][] = []
  loop(options)

  function loop(options: COption[]) {
    for (let i = 0; i < options.length; i++) {
      const currentOption = options[i]
      let currentPath = []
      currentPath.push(currentOption.value)
      if (currentOption.childOptions.length === 0) {
        restPath.push([...currentPath])

        currentPath.pop()
        continue
      }
      loop(currentOption.childOptions)
    }
  }

  return restPath
}

export function getParentNode(
  options: COption[],
  paths: string[]
): COption | null {
  let newPath = [...paths]
  newPath.pop()
  const result = getCurrentNode(options, newPath)
  return result
}

export function expandOrCollapseOptions(
  options: COption[],
  selected: boolean
): void {
  for (let i = 0; i < options.length; i++) {
    options[i].show = selected
    if (options[i].childOptions.length === 0) continue
    expandOrCollapseOptions(options[i].childOptions, selected)
  }
}

export function selectedOrUnselectedAll(
  options: COption[],
  selected: boolean
): void {
  for (let i = 0; i < options.length; i++) {
    options[i].selected = selected
    selectedOrUnselectedAll(options[i].childOptions, selected)
  }
}

export function selectOrUnselectAllParentNode(
  options: COption[],
  paths: string[]
): void {
  const pathsClone = [...paths]
  while (pathsClone.length > 0) {
    let parentNode = getParentNode(options, pathsClone)
    if (!parentNode) return
    if (parentNode?.childOptions.some((item) => item.selected))
      parentNode.selected = true
    else parentNode.selected = false
    pathsClone.pop()
  }
}

export function expandOrCollapseChildren(
  options: COption[],
  paths: string[]
): void {
  let currentNode = getCurrentNode(options, paths)
  let isExpand = currentNode?.childOptions.every((item) => item.show)
  currentNode?.childOptions.map((item) => {
    item.show = !isExpand
    return item
  })
}

// export function selectOrUnSelectAll(options: COption[], selected: boolean) {
//   for (let i = 0; i < options.length; i++) {
//     if (options[i].childOptions.length === 0) continue
//     selectOrUnSelectAll(options[i].childOptions, selected)
//   }
// }

export function selectOrUnSelectAll(options: COption[], selected: boolean) {
  options.forEach((option) => {
    option.selected = selected
    if (option.childOptions && option.childOptions.length > 0) {
      selectOrUnSelectAll(option.childOptions, selected)
    }
  })
}

export function checkAtLeastOneOptionWasSelected(options: COption[]): boolean {
  for (let i = 0; i < options.length; i++) {
    const currentOption = options[i]
    if (currentOption.selected) return true
    if (currentOption.childOptions.length === 0) continue
    checkAtLeastOneOptionWasSelected(currentOption.childOptions)
  }
  return false
}

export function checkAtLeastOneOptionWasDisabled(options: COption[]): boolean {
  for (let i = 0; i < options.length; i++) {
    const currentOption = options[i]
    if (currentOption.disabled) return true
    if (currentOption.childOptions.length === 0) continue
    checkAtLeastOneOptionWasSelected(currentOption.childOptions)
  }
  return false
}

export function getPairSelected(
  options: COption[]
): { disabled: boolean; text: string[] }[] {
  let result: { disabled: boolean; text: string[] }[] = []
  let path: string[] = []
  loopSelected(options)

  function loopSelected(options: COption[]) {
    // debugger
    for (let i = 0; i < options.length; i++) {
      const currentOptions = options[i]
      const { uuid, selected, childOptions, label, value, disabled } =
        currentOptions
      if (!selected) continue

      path.push(`${uuid};${label};${value};${disabled}`)
      if (childOptions.length === 0) {
        result.push({ disabled: Boolean(disabled), text: [...path] })
        path.pop()
        continue
      }

      loopSelected(currentOptions.childOptions)
      path.pop()
    }
  }

  return result
}

export function getPathOfOptionSelected(options: COption[], uuid: string) {
  let result: string[] = []
  let success = false

  function trace(options: COption[]) {
    let i = 0
    for (i = 0; i < options.length; i++) {
      if (success) break
      const currentOption = options[i]
      const { uuid: currentUuid } = currentOption

      result.push(currentUuid)

      if (currentOption.childOptions.length === 0) {
        if (currentUuid === uuid) {
          success = true
          return
        }
        result.pop()
        continue
      }

      if (currentUuid === uuid) {
        success = true
        return
      }

      trace(currentOption.childOptions)
    }
    if (i === options.length && !success) {
      result.pop()
    }
  }

  trace(options)
  return result
}

export function getPathValueOfOptionSelected(options: COption[], uuid: string) {
  let result: string[] = []
  let success = false

  function trace(options: COption[]) {
    let i = 0
    for (i = 0; i < options.length; i++) {
      if (success) break
      const currentOption = options[i]
      const { uuid: currentUuid, value } = currentOption

      result.push(value)

      if (currentOption.childOptions.length === 0) {
        if (currentUuid === uuid) {
          success = true
          return
        }
        result.pop()
        continue
      }

      if (currentUuid === uuid) {
        success = true
        return
      }

      trace(currentOption.childOptions)
    }
    if (i === options.length && !success) {
      result.pop()
    }
  }

  trace(options)
  return result
}

export function getAllPathValues(options: COption[]) {
  let result: string[][] = []
  let line: string[] = []

  function trace(options: COption[]) {
    for (let i = 0; i < options.length; i++) {
      const currentOption = options[i]
      const { value } = currentOption

      line.push(value)

      if (currentOption.childOptions.length === 0) {
        result.push([...line])
        line.pop()
        continue
      }

      trace(currentOption.childOptions)
    }

    line.pop()
  }

  trace(options)
  return result
}

export function forceDisableOptions(
  options: COption[],
  optionDisableds: string[][]
) {
  const flattenOptionDisableds: string[][] = []
  optionDisableds.forEach((item) => {
    flattenArray(item).forEach((item) => flattenOptionDisableds.push(item))
  })
  for (let i = 0; i < flattenOptionDisableds.length; i++) {
    const currentValuePath = flattenOptionDisableds[i]
    const currentOption = getNodeByValuePath(options, currentValuePath)
    if (!currentOption) continue
    currentOption.disabled = true
  }
}

function getNodeByValuePath(
  options: COption[],
  valuePath: string[]
): COption | null {
  // debugger
  for (let i = 0; i < options.length; i++) {
    const currentOption = options[i]
    const { level, value } = currentOption
    if (level >= valuePath.length) continue
    const isValueInPath = valuePath[level] === value

    if (isValueInPath) {
      if (
        currentOption.childOptions.length === 0 ||
        level === valuePath.length - 1
      ) {
        return currentOption
      }
      return getNodeByValuePath(currentOption.childOptions, valuePath)
    }

    continue
  }
  return null
}

function flattenArray(optionDisableds: string[]) {
  const result = []
  for (let i = 0; i < optionDisableds.length; i++) {
    result.push(optionDisableds.slice(0, i + 1))
  }

  return result
}
