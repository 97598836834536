import { useEffect, useState } from 'react'
import { genKey, sleep } from '../utils'

export default function GenKeyHook() {
  const [code, setCode] = useState<string>('')
  useEffect(() => {
    const MAX_RETRY = 10
    const KEY_DIGIT = 10
    let keyOb = genKey(KEY_DIGIT)
    let count = 0
    while (!keyOb[1] && count < MAX_RETRY) {
      keyOb = genKey(KEY_DIGIT)
      sleep(10)
      count++
    }
    if (!keyOb[1]) {
      alert('Can not auto gen code!')
    } else {
      setCode(keyOb[0] as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { code }
}
