import { useClickOutside } from '@mantine/hooks'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import './cselect.scss'
import {
  checkAtLeastOneOptionWasDisabled,
  checkAtLeastOneOptionWasSelected,
  expandOrCollapseOptions,
  forceDisableOptions,
  getAllPathValues,
  getCurrentNode,
  getPairSelected,
  getPathOfOptionSelected,
  getPathValueOfOptionSelected,
  getRest,
  getRestPathValue,
  selectedOrUnselectedAll,
  selectOrUnSelectAll,
  selectOrUnselectAllParentNode,
} from './func/functions'
import ArrowDown from './img/arrow-down.svg'
import XClose from './img/x-close.svg'
import { COption } from './model/COption'

interface CSelectProps {
  name?: string
  options: COption[]
  width?: number
  height?: number
  optionsAreaMaxHeight?: number
  forceReset?: number
  forceOpen?: boolean
  disabled?: boolean
  forceInvalid?: boolean
  required?: boolean
  onChange: (options: COption[]) => void
  onClick?: (name: string) => void
  onOpen?: () => void
  onClose?: () => void
  onClickInside?: () => void
  onClickOutside?: () => void
  onSelect?: (pathsValue: string[], otherPaths: string[][]) => void
  onUnselect?: (pathsValue: string[], otherPaths: string[][]) => void
  onSelectAll?: (pathsValue: string[][]) => void
  onUnselectAll?: () => void
  id?: string
  nextId?: string
  previousId?: string
}

function genderListOption(
  options: COption[],
  onClickOption: (uuid: string) => void,
  onExpandCollapse: (uuid: string) => void
): JSX.Element[] {
  let result: JSX.Element[] = []

  jsonToUI(options)

  function jsonToUI(options: COption[]): any {
    // debugger
    for (let i = 0; i < options.length; i++) {
      const currentOption = options[i]
      const { level, show, value, uuid, selected, disabled, childOptions } =
        currentOption

      if (currentOption.childOptions.length === 0)
        result.push(
          <div
            key={uuid}
            className="option"
            style={{
              paddingLeft: 10 + level * 22,
              display: show ? 'flex' : 'none',
            }}
            data-level={level}
            data-value={value}
            data-uuid={uuid}
          >
            <label className="containers">
              <input
                type="checkbox"
                disabled={disabled}
                checked={selected || false}
              />
              <span
                className={disabled ? 'checkmark-disabled' : 'checkmark'}
                onClick={() => (disabled ? () => {} : onClickOption(uuid))}
              ></span>
            </label>
            <div className="label" onClick={() => onExpandCollapse(uuid)}>
              {currentOption.label || 'null'}
              {childOptions.length > 0 && <img src={ArrowDown} alt="" />}
            </div>
          </div>
        )
      else {
        result.push(
          <div
            key={uuid}
            className="option"
            style={{
              paddingLeft: 10 + level * 22,
              display: show ? 'flex' : 'none',
            }}
            data-level={level}
            data-value={value}
            data-uuid={uuid}
          >
            <label className="containers">
              <input type="checkbox" disabled checked={selected || false} />
              <span
                className={disabled ? 'checkmark-disabled' : 'checkmark'}
                onClick={() => (disabled ? () => {} : onClickOption(uuid))}
              ></span>
            </label>
            <div className="label" onClick={() => onExpandCollapse(uuid)}>
              {currentOption.label}
              {childOptions.length > 0 && <img src={ArrowDown} alt="" />}
            </div>
          </div>
        )
        jsonToUI(currentOption.childOptions)
      }
    }
  }

  return result
}

export default function CSelect(props: CSelectProps) {
  const { options } = props
  const [optionsState, setOptionsState] = useState<COption[]>([])
  const [selected, setSelected] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [pairs, setPairs] = useState<{ disabled: boolean; text: string[] }[]>(
    []
  )
  const [isOpenList, setIsOpenList] = useState<boolean>(
    props?.forceOpen || false
  )
  const [focussed, setFocussed] = useState<boolean>(false)
  const handleClickOutside = () => {
    if (props?.onClickOutside) props.onClickOutside()
    // console.log(props.name, props.forceOpen)
    if (props?.forceOpen) return
    if (props?.onClose) props.onClose()
    setIsOpenList(false)
  }

  const ref = useClickOutside(handleClickOutside)

  useEffect(() => {
    setOptionsState(options)
  }, [options])

  useEffect(() => {
    const atLeastOneOptionWasSelected =
      checkAtLeastOneOptionWasSelected(optionsState)
    const atLeastOneOptionWasDisabled =
      checkAtLeastOneOptionWasDisabled(optionsState)
    setSelected(atLeastOneOptionWasSelected)
    setDisabled(atLeastOneOptionWasDisabled)
    const a = getPairSelected(cloneDeep(optionsState))
    setPairs(a)
  }, [optionsState])

  useEffect(() => {
    if (!props.forceReset) return
    onUnselectAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.forceReset])

  useEffect(() => {
    setIsOpenList(props?.forceOpen || false)
  }, [props.forceOpen])

  const onExpandCollapse = (uuid: string) => {
    const paths = getPathOfOptionSelected(optionsState, uuid)
    const optionsClone = cloneDeep(optionsState)
    const currentNode = getCurrentNode(optionsClone, paths)
    let isExpand = currentNode?.childOptions.every((item) => item.show)
    if (isExpand) expandOrCollapseOptions(getRest(optionsClone, paths), false)
    else {
      currentNode?.childOptions.map((item) => {
        item.show = true
        return item
      })
    }
    setOptionsState(optionsClone)
  }

  const onClickOption = (uuid: string) => {
    const optionsClone = cloneDeep(optionsState)

    const paths = getPathOfOptionSelected(optionsState, uuid)
    let currentNode = getCurrentNode(optionsClone, paths)
    const pathsValue = getPathValueOfOptionSelected(optionsState, uuid)
    const restPathValue = getRestPathValue(getRest(optionsClone, paths))

    for (let i = 0; i < restPathValue.length; i++) {
      const concatPath = pathsValue.concat(restPathValue[i])
      restPathValue[i] = concatPath
    }

    if (!currentNode) return
    if (!currentNode.selected) {
      currentNode.selected = true
      if (props?.onSelect) props.onSelect([...pathsValue], [...restPathValue])
      selectedOrUnselectedAll(getRest(optionsClone, paths), true)
    } else {
      currentNode.selected = false

      if (props?.onUnselect)
        props.onUnselect([...pathsValue], [...restPathValue])
      selectedOrUnselectedAll(getRest(optionsClone, paths), false)
    }

    selectOrUnselectAllParentNode(optionsClone, paths)
    setPairs(getPairSelected(optionsClone))
    setOptionsState(optionsClone)
    props.onChange(optionsClone)
  }

  const onSelectAll = () => {
    const optionsClone = cloneDeep(optionsState)

    selectOrUnSelectAll(optionsClone, true)
    setPairs(getPairSelected(optionsClone))
    setOptionsState(optionsClone)
    props.onChange(optionsClone)
    if (props?.onSelectAll) {
      const pathsValue = getAllPathValues(optionsClone)
      props.onSelectAll(pathsValue)
    }
  }
  const onUnselectAll = () => {
    const optionsClone = cloneDeep(optionsState)

    selectOrUnSelectAll(optionsClone, false)
    setPairs(getPairSelected(optionsClone))
    setOptionsState(optionsClone)
    props.onChange(optionsClone)
    if (props.onUnselectAll) props.onUnselectAll()
  }

  const onDiscardPair = (paths: string[]) => {
    const optionsClone = cloneDeep(optionsState)
    let newPath = [...paths].map((item) => {
      let [uuid] = item.split(';')
      return uuid
    })

    let currentNode = getCurrentNode(optionsClone, newPath)
    if (!currentNode) return

    currentNode.selected = false
    selectedOrUnselectedAll(getRest(optionsClone, newPath), false)
    selectOrUnselectAllParentNode(optionsClone, newPath)
    setPairs(getPairSelected(optionsClone))
    setOptionsState(optionsClone)
    props.onChange(optionsClone)

    if (props.onUnselect) {
      const lastUuId = newPath[newPath.length - 1]
      const pathsValue = getPathValueOfOptionSelected(optionsState, lastUuId)
      props.onUnselect(pathsValue, [])
    }
  }

  const pairsToUI = (
    pairs: { disabled: boolean; text: string[] }[],
    onDiscardPair: (paths: string[]) => void
  ): JSX.Element[] => {
    let results: JSX.Element[] = []
    for (let i = 0; i < pairs.length; i++) {
      let result: JSX.Element[] = []
      const pair = pairs[i]
      const text = pair.text
      const disabled = pair.disabled
      for (let j = 0; j < text.length; j++) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [uuid, label, value] = text[j].split(';')
        let style
        if (j % 2 === 0)
          style = {
            backgroundColor: '#88A9EC',
            color: 'white',
          }
        else
          style = {
            backgroundColor: 'white',
            color: '#4F6CA7',
          }
        result.push(
          <span key={j} className="tag" style={style}>{`${label}`}</span>
        )
      }

      let element = (
        <div className={!disabled ? 'element' : 'element-disabled'} key={i}>
          {result}
          {!disabled && (
            <span className="discard">
              <img
                src={XClose}
                alt=""
                onClick={disabled ? () => {} : () => onDiscardPair(text)}
              />
            </span>
          )}
        </div>
      )
      results.push(element)
    }

    return results
  }

  return (
    <div
      id={props.id}
      data-next-id={props.nextId}
      data-previous-id={props.previousId}
      tabIndex={0}
      className="cselect"
      style={{ width: props.width, height: props.height }}
      onClick={() => {
        setFocussed(true)
        if (props?.onClick) props.onClick(props?.name || '')
        if (props?.onClickInside) props.onClickInside()
        if (props?.disabled) return
        if (props?.onOpen) props.onOpen()
        setIsOpenList(true)
      }}
      ref={ref}
    >
      <div
        className="result c-select"
        ref={ref}
        style={{
          border:
            (props?.required &&
              pairs.length === 0 &&
              focussed &&
              !isOpenList) ||
            props.forceInvalid
              ? '2px solid #f26d6d'
              : '',
          height: props.height,
        }}
      >
        {pairsToUI(pairs, onDiscardPair)}
      </div>

      <div
        className="options"
        style={{ display: isOpenList || props?.forceOpen ? 'block' : 'none' }}
      >
        <div>
          <label className="select-all">
            <input type="checkbox" disabled checked={selected} />
            <span
              className={disabled ? 'checkmark-disabled' : 'checkmark'}
              onClick={
                disabled ? () => {} : selected ? onUnselectAll : onSelectAll
              }
            ></span>
          </label>
        </div>
        <div className="line"></div>
        <div
          className="list-options"
          style={{ maxHeight: props?.optionsAreaMaxHeight, overflow: 'auto' }}
        >
          {genderListOption(optionsState, onClickOption, onExpandCollapse)}
        </div>
      </div>
    </div>
  )
}
